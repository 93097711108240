'use client';

import { Fragment } from 'react';

import { useStore as useZustandStore } from 'zustand';

import { ChildrenProps } from '@/types/common.types';

import { type BoundStore, createBoundStore } from '@/zustand-store';

// =================================================================

export const StoreProvider = (props: ChildrenProps) => {
  const { children } = props;

  return <Fragment>{children}</Fragment>;
};

// =================================================================

export const useStore = <T extends any>(selector: (store: BoundStore) => T): T => {
  return useZustandStore(createBoundStore, selector);
};
